<template>
    <div class="yj-conten" style="height: calc(100% - 36px);background-color:#fff;">
        <a-row justify="space-between" style="background: #fff; padding:20px">
            <a-col>
                <a-radio-group default-value="a" @change="onRadioChange">
                    <a-radio-button value="a"> 基本信息 </a-radio-button>
                    <a-radio-button value="b"> 门店地址 </a-radio-button>
                </a-radio-group>
            </a-col>

        </a-row>

        <div style="margin-top:18px;">
            <a-form-model ref="ruleForm" :model="FormModel" :rules="rules" v-bind="layout">
                <a-form-model-item :wrapper-col="{ span: 6, offset: 2 }" v-if="MessageShow.Show">
                    <a-alert :message="MessageShow.Text" :type="MessageShow.Type" show-icon />
                </a-form-model-item>
                <a-form-model-item label="机构名称" prop="name" hasFeedback>
                    <a-input placeholder="请输入机构名称" v-model="FormModel.name" autocomplete="off" />

                </a-form-model-item>
                <a-form-model-item label="机构全称" prop="fullname" hasFeedback>
                    <a-input placeholder="请输入机构全称" v-model="FormModel.fullname" autocomplete="off" />

                </a-form-model-item>
                <a-form-model-item label="机构编码" prop="code" hasFeedback>
                    <a-input placeholder="请重新输入机构编码" v-model="FormModel.code" autocomplete="off" />

                </a-form-model-item>
                <a-form-item label="营业执照" style="margin-bottom:0px;"
                             :label-col="layout.labelCol"
                             :wrapper-col="layout.wrapperCol">
                    <a-upload name="uimage"
                              :data="ParamData"
                              list-type="picture-card"
                              class="avatar-uploader"
                              action="/FileServer/Upload?secpath=license"
                              :remove="removeImg"
                              :fileList="FormModel.fileList"
                              @change="imgChange">
                        <div v-if="FormModel.fileList.length < 1">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">
                                营业执照
                            </div>
                        </div>
                    </a-upload>
                </a-form-item>
                <a-form-item label="店铺Logo" style="margin-bottom:0px;"
                             :label-col="layout.labelCol"
                             :wrapper-col="layout.wrapperCol">
                    <a-upload name="LogPath"
                              :data="ParamDataLogo"
                              list-type="picture-card"
                              class="avatar-uploader"
                              action="/FileServer/Upload?secpath=logo"
                              :fileList="FormModel.fileList1"
                              :remove="removeLogo"
                              @change="LogoChange">
                        <div v-if="FormModel.fileList1.length < 1">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">
                                Logo
                            </div>
                        </div>
                    </a-upload>
                </a-form-item>
                <a-form-item label="行业分类"
                             :label-col="layout.labelCol"
                             :wrapper-col="layout.wrapperCol">

                    <a-select v-model="Limitvalue"
                              mode="multiple"
                              style="width: 100%"
                              placeholder="例如：家长类"
                              option-label-prop="label"
                              @select="Okselect">
                        <a-select-option :label="item.Name" v-for="item in RoleList" :key="item.ID" :value="item.ID.toString()">
                            <span role="img" >
                                {{item.Name}}
                            </span>
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-model-item :wrapper-col="{ span: 2, offset: 4 }">
                    <a-button type="primary" @click="submitForm('ruleForm')">保存</a-button>
                </a-form-model-item>
            </a-form-model>

        </div>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    export default {
        name: "EditPassword",
        data() {
            return {
                MessageShow: {
                    Text: "",
                    Show: false,
                    Type: "error"
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                ParamDataLogo: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                FormModel: {
                    name: '',
                    fullname: '',
                    code: '',
                    fileList: [],
                    fileList1: [],
                    uimage: "",
                    LogPath: "",
                    BizSecondIDs: "",
                    BizSecondStr: "",
                },
                rules: {
                    name: [
                        { validator: this.InputChange },
                        { message: "请输入机构名称", required: true }
                    ],
                    fullname: [
                        { validator: this.InputChange },
                        { message: "请输入机构全称", required: true },
                    ],
                    code: [
                        { validator: this.InputChange },
                        { message: "请重新输入机构编码", required: true },
                    ]
                },
                layout: {
                    labelCol: { span: 2 },
                    wrapperCol: { span: 6 },
                },
                RoleList: [], //机构限制类型集合
                Limitvalue: [],
            };
        },
        methods: {
            handlePreview(file) {
                console.log(file);
            },
            //获取机构限制类型列表
            GetStoreBusinessTypeList() {
                var self = this;
                var op = {
                    url: "/SystemSetting/GetStoreBusinessTypeList",
                    OnSuccess: function (data) {
                        console.log(data);
                        self.RoleList = data.data;
                    }
                };
                http.Post(op);
            },
            //选中事件
            Okselect(value) {
                console.log("fdsaf:", value);
                if (this.Limitvalue.length > 3)
                    this.$message.warning('最多可以选择三个类型');
                this.Limitvalue.splice(3, 1);
                this.FormModel.BizSecondIDs = this.Limitvalue.toString();
            },
            onRadioChange: function (data) {
                var self = this;
                var val = data.target.value;
                if (val == 'b') {
                    self.$router.push({ name: "ShopManage_SiteList" });
                }
                //console.log(data);
            },
            //获取多选框的text值
            GetSelectText() {
                var da = this.Limitvalue;
                var Rol = this.RoleList;
                var Text = [];
                for (var i = 0; i < da.length; i++) {
                    for (var j = 0; j < Rol.length; j++) {
                        if (da[i] == Rol[j].ID) {
                            Text.push(Rol[j].Name);
                            break;
                        }
                    }
                }
                //console.log("fsad:", Text);
                this.FormModel.BizSecondStr = Text.toString();
            },
            InputChange: function (rule, value, callback) {
                this.MessageShow.Show = false;
                callback();
            },
            imgChange: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;

                }
                self.FormModel.fileList = info.fileList;
                console.log("info:", info.fileList[0].response);
                self.FormModel.uimage = info.fileList[0].response;
            },
            LogoChange(info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;

                }
                self.FormModel.fileList1 = info.fileList;
                console.log("info:", info.fileList[0].response);
                self.FormModel.LogPath = info.fileList[0].response;
            },
            removeImg: function () {
                console.log("删除图片Img");
                //console.log(data);
                var self = this;
                self.FormModel.uimage = "";

            },
            removeLogo:function() {
                console.log("删除图片Logo");
                //console.log(data);
                var self = this;
                self.FormModel.LogPath = "";
            },
            submitForm(formName) {
                this.GetSelectText();
                var self = this;
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var op = {
                            url: "/SystemSetting/EidtCustomerInfo",
                            data: self.FormModel,
                            OnSuccess: function () {
                                self.MessageShow.Text = "修改成功";
                                self.MessageShow.Show = true;
                                self.MessageShow.Type = "success";
                            },
                            ErrorMessageShow: function (msg) {
                                self.MessageShow.Text = msg;
                                self.MessageShow.Show = true;
                                self.MessageShow.Type = "error";
                            }
                        };
                        http.Post(op);
                    } else {
                        return false;
                    }
                });
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/SystemSetting/GetCustomer",
                    data: {

                    },
                    OnSuccess: function (data) {
                        console.log("data--");
                        console.log(data);

                        if (data.data != null) {
                            data.data.LogPath = data.data.LogPath && data.data.LogPath.replace(/upload\//g, 'upload2/') || "";
                            self.FormModel.fullname = data.data.FullName;
                            self.FormModel.name = data.data.Name;
                            self.FormModel.code = data.data.UnionCreditCode;
                            self.FormModel.uimage = data.data.UnionCreditImg;
                            self.FormModel.LogPath = data.data.LogPath;
                            self.FormModel.BizSecondIDs = data.data.BizSecondIDs;
                            if (data.data.UnionCreditImg != "" && data.data.UnionCreditImg != null) {
                                self.FormModel.fileList = [{
                                    name: data.data.Picture,
                                    response: data.data.Picture,
                                    status: "done",
                                    uid: "0",
                                    url: data.data.UnionCreditImg
                                }];
                            }
                            if (data.data.LogPath != "" && data.data.LogPath != null) {
                                self.FormModel.fileList1 = [{
                                    name: data.data.Picture,
                                    response: data.data.Picture,
                                    status: "done",
                                    uid: "0",
                                    url: data.data.LogPath
                                }];
                            }
                            
                            if (data.data.BizSecondIDs != null) {
                                self.Limitvalue = data.data.BizSecondIDs.split(',');
                                console.log("hfdkjs:", self.Limitvalue);
                            }
                        }
                    }
                };
                http.Post(op);
            }

        },
        mounted: function () {
            var self = this;
            //获取机构限制类型列表
            self.GetStoreBusinessTypeList();
            self.getTableData();
        },
        computed: {},
        components: {
        }
    };
</script>
<style>
    .stars {
        width: 60px;
        overflow: hidden;
        background: url('/Image/honor/star.svg') repeat-x left center /12px 12px;
        height: 18px;
        position: relative;
    }

    .stars-f {
        background: url('/Image/honor/starfull.svg') repeat-x left center /12px 12px;
        height: 18px;
        left: 0;
        content: '';
        bottom: 0;
        position: absolute;
    }

    .roleTag {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .light-spot {
        margin: 0px;
        padding: 10px 15px;
    }

    .light-spot {
        border: 1px solid #e5e5e5;
        padding: 8px 15px;
        color: #333;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
    }

        .slect, .light-spot:hover {
            color: #32a9ff;
            border-color: #32a9ff;
            background: url("/Image/slctip.svg") no-repeat right 20px /20px 20px;
        }
</style>